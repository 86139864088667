import React from "react";
import path from "path";
import { graphql, navigate } from "gatsby";
import Layout from "../components/Layout";
import Card from "../components/Card";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
// import Pagination from "materialui-pagination-component";
import Pagination from "@material-ui/lab/Pagination";
import { isBrowser } from "react-device-detect";

const Posts = ({ posts, pathPrefix }) => {
  return (
    <Grid container spacing={3}>
      {posts.map(
        ({
          node: {
            excerpt,
            fileAbsolutePath,
            frontmatter: { id, title, featuredImage }
          }
        }) => {
          const postDate = path
            .basename(fileAbsolutePath)
            .split("-")
            .splice(0, 3)
            .join("-");
          return (
            <Grid item xs={12} sm={4} key={id}>
              <Card
                featuredImage={featuredImage}
                title={title}
                url={`/${pathPrefix}/${id}`}
                postDate={postDate}
                excerpt={excerpt}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default function ResultsTagTemplate({
  data: {
    site: {
      siteMetadata: {
        templates: {
          posts: {
            filters: {
              tag: {
                pathPrefix,
                pagination: { resultsPerPage }
              }
            }
          }
        }
      }
    },
    allMdx: { edges: posts }
  },
  pageContext: { tag, totalPages, currentPage }
}) {
  const Pager = (
    <Box paddingTop={2} paddingBottom={4}>
      <Pagination
        style={{
          padding: 8,
          color: "white",
          backgroundColor: "white"
        }}
        variant="outlined"
        size="large"
        shape="rounded"
        // color=""
        // color="secondary"
        page={currentPage}
        count={Math.ceil(totalPages / resultsPerPage)}
        onChange={(event, page) =>
          navigate(`/${pathPrefix}/${tag}/page/${page}`)
        }
      />{" "}
    </Box>
  );

  return (
    <Layout>
      <Box flexGrow={1} width="100%" maxWidth={960} marginX="auto">
        <Box padding={2}>
          <Box textAlign="center" padding={4}>
            <Box marginBottom={4}>
              <Typography
                color="primary"
                variant="h3"
                style={{
                  fontWeight: "bold",
                  fontFamily:
                    "Work Sans, -apple-system, BlinkMacSystemFont, Reem Kufi, sans-serif",
                  marginBottom: 4,
                  textDecoration: "none"
                }}
              >
                game: {tag}
              </Typography>
            </Box>
            <Divider variant="middle" />
          </Box>

          {isBrowser ? undefined : Pager}
          <Posts posts={posts} pathPrefix={pathPrefix} />
          {isBrowser ? Pager : undefined}

          {/* <Pagination
            selectVariant="tab"
            page={currentPage}
            totalPages=c
            onChange={page => navigate(`/${pathPrefix}/${tag}/page/${page}`)}
          />  */}
        </Box>
      </Box>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $tag: String!) {
    site {
      siteMetadata {
        templates {
          posts {
            filters {
              tag {
                pathPrefix
                pagination {
                  resultsPerPage
                }
              }
            }
          }
        }
      }
    }
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/" }
        frontmatter: { tags: { in: [$tag] } }
      }
      sort: { order: DESC, fields: [fileAbsolutePath] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fileAbsolutePath
          frontmatter {
            id
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
